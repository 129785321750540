import request from "../request";

export function fetchList({ orgId }) {
    return request({
        url: `/user-service/orgInstituteGroup/list/${orgId}`,
    })
}
export function add(data) {
    return request({
        url: `/user-service/orgInstituteGroup/save`,
        method: 'post',
        data,
    })
}

export function edit(data) {
    return request({
        url: `/user-service/orgInstituteGroup/modify`,
        method: 'post',
        data,
    })
}

export function remove(data) {
    return request({
        url: `/user-service/orgInstituteGroup/delete`,
        method: 'post',
        data,
    })
}