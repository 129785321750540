<template>
  <div>
    <Pane />

    <a-card class="container">
      <div class="right">
        <a-button type="primary" @click="onAdd">添加</a-button>
      </div>
      <Padding />

      <a-table
        bordered
        :data-source="list"
        :pagination="false"
        childrenColumnName="list"
        rowKey="id"
      >
        <a-table-column title="名称" data-index="name"> </a-table-column>
        <a-table-column
          title="负责人"
          data-index="master1Name"
        ></a-table-column>
        <a-table-column title="负责人(副)" data-index="master2Name">
        </a-table-column>

        <a-table-column title="类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="specialOrg" :dictValue="text.type" />
          </template>
        </a-table-column>

        <a-table-column title="职能" data-index="remarks"> </a-table-column>
        <a-table-column title="排序" align="center">
          <template slot-scope="text">
            {{ text.sort }}
          </template>
        </a-table-column>
        <a-table-column title="状态" align="center">
          <template slot-scope="text">
            <DataDictFinder
              dictType="status"
              :dictValue="String(text.status)"
            />
          </template>
        </a-table-column>

        <a-table-column title="操作" align="right">
          <template slot-scope="text">
            <a-space>
              <a href="#" @click.prevent="onEdit(text)">修改</a>
              <a href="#" class="danger" @click.prevent="onRemove(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <a-modal
      :visible="visible"
      :title="detail.type ? '编辑' : '添加'"
      :footer="null"
      @cancel="cancel"
      width="640px"
    >
      <a-form :form="form" @submit="handleSubmit" :colon="false">
        <a-row :gutter="8">
          <a-col :span="12">
            <a-form-item label="名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: detail.name,
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item>
              <span slot="label" class="required">负责人</span>
              <div>
                <EmployeeSelector
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        master1 = arr[0];
                      } else {
                        master1 = {};
                      }
                    }
                  "
                  :value="master1.name ? [master1] : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="master1.name">
                      {{ master1.name }}
                    </div>
                    <div v-else>&nbsp;</div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="负责人(副)">
              <div>
                <EmployeeSelector
                  :single="false"
                  @change="
                    (arr) => {
                      if (arr.length > 0) {
                        master2List = arr;
                      } else {
                        master2List = [];
                      }
                    }
                  "
                  :value="master2List.length > 0 ? master2List : []"
                >
                  <a-button block style="text-align: left">
                    <div v-if="master2List.length > 0">
                      {{ master2List.map((item) => item.name).join(", ") }}
                    </div>
                    <div v-else>
                      <span class="placeholder">可多选</span>
                    </div>
                  </a-button>
                </EmployeeSelector>
              </div>
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="职能">
              <a-textarea
                v-decorator="[
                  'remarks',
                  {
                    initialValue: detail.remarks,
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="类型">
              <!-- initialValue: detail.type, -->
              <a-select
                style="width: 100%"
                v-decorator="[
                  'type',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12" v-if="form.getFieldValue('type') === 'group'">
            <a-form-item label="所属">
              <a-select
                style="width: 100%"
                v-decorator="[
                  'pid',
                  {
                    initialValue: detail.pid,
                    rules: [
                      {
                        required: true,
                        message: '请选择！',
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  v-for="item in list.filter(
                    (item) => item.type === 'institute'
                  )"
                  :key="item.id"
                  :value="item.id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="排序">
              <a-input-number
                style="width: 100%"
                placeholder="值越大越靠前"
                v-decorator="[
                  'sort',
                  {
                    initialValue: detail.sort,
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="状态">
              <a-radio-group
                v-decorator="[
                  'status',
                  {
                    initialValue: detail.status ? String(detail.status) : null,
                    rules: [{ required: true, message: '请选择！' }],
                  },
                ]"
              >
                <a-radio
                  v-for="item in statusList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button htmlType="submit" type="primary" :loading="saveLoading"
              >保存</a-button
            >
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import EmployeeSelector from "@/components/employee-selector";
import { mapGetters } from "vuex";
import { fetchList, add, remove, edit } from "@/api/human-resources/group";
export default {
  components: {
    EmployeeSelector,
  },

  data() {
    return {
      orgId: null,
      list: [],

      form: this.$form.createForm(this),
      visible: false,

      master1: {},
      master2List: [],

      saveLoading: false,

      detail: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    statusList() {
      return this.findDataDict("status");
    },
    typeList() {
      return this.findDataDict("specialOrg");
    },
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    if (id) {
      this.orgId = id;
      this.getList();
    } else {
      this.$message.error("部门ID错误");
    }
  },

  methods: {
    getList() {
      fetchList({
        orgId: this.orgId,
      }).then((res) => {
        if (res && Array.isArray(res.list)) {
          const institute = res.list.filter(
            (item) => item.type === "institute"
          );
          institute.forEach((item) => {
            const list = res.list.filter(
              (element) => element.type === "group" && element.pid === item.id
            );
            if (list.length > 0) {
              item.list = list;
            }
          });
          this.list = Object.freeze(institute);
        }
      });
    },

    onRemove(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove(text).then(() => {
            that.getList();
          });
        },
      });
    },

    onAdd() {
      this.visible = true;
    },
    onEdit(text) {
      this.detail = text;

      this.form.setFieldsValue({
        type: text.type,
      });

      this.master1 = {
        userId: text.master1,
        name: text.master1Name,
      };

      if (text.master2) {
        const ids = text.master2.split(",");
        const names = text.master2Name.split(",");

        for (let i = 0; i < ids.length; i++) {
          this.master2List.push({
            userId: ids[i],
            name: names[i],
          });
        }
      }

      this.visible = true;
    },
    cancel() {
      this.form.resetFields();
      this.master1 = {};
      this.master2List = [];
      this.detail = {};
      this.visible = false;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.master1.name) {
            this.$message.error("请选择负责人！");
            return;
          }

          let master2, master2Name;
          if (this.master2List.length > 0) {
            master2 = this.master2List.map((item) => item.userId).join();
            master2Name = this.master2List.map((item) => item.name).join();
          }

          const params = {
            id: this.detail.id,
            ...values,

            master1: this.master1.userId,
            master1Name: this.master1.name,
            master2,
            master2Name,

            pid: values.type === "group" ? values.pid : null,

            orgId: this.orgId,
          };

          this.saveLoading = true;
          if (this.detail.id) {
            edit(params)
              .then(() => {
                this.getList();
              })
              .finally(() => {
                this.saveLoading = false;
                this.cancel();
              });
          } else {
            add(params)
              .then(() => {
                this.getList();
              })
              .finally(() => {
                this.saveLoading = false;
                this.cancel();
              });
          }
        } else {
          this.$message.error("请填写数据！");
        }
      });
    },
  },
};
</script>